<template>
  <div class="rightLink">
    <div class="bottomDiv">
      <div class="title">用户钱包</div>
      <div class="formDiv" style="width: 80vw; margin-left: 3vw">
        <el-form label-position="left" label-width="60px">
          <el-row :gutter="20">
            <el-col :span="1"></el-col>
            <el-col :span="6">
              <el-form-item label="手机号:">
                <el-input
                  v-model="FormData.phone"
                  placeholder="请输入用户手机号"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col v-show="isadmin" :span="6">
              <el-form-item label="运营商:">
                <el-select
                  v-model="FormData.operator_id"
                  placeholder="请选择运营商"
                  style="width: 100%"
                  clearable
                >
                  <el-option
                    v-for="aa in users"
                    :key="aa.username"
                    :value="aa.id"
                    :label="aa.username"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="1">
              <div class="BtnSearch" @click="searchBtn()">查询</div>
            </el-col>
            <el-col :span="1" style="margin-left:20px">
              <Data2Excel api='url' :data="data" fileName="用户钱包余额"></Data2Excel>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <!-- 表格部分 -->
      <div class="tableDiv">
        <el-table
          ref="multipleTableRef"
          :data="tableData"
          border
          v-loading="loading"
          height="510px"
          style="width: 90%; margin: 20px auto; overflow-y: auto"
        >
          <el-table-column label="序号" type="index" align="center" width="60">
            <template #default="{ $index }">
              {{ (currentPage - 1) * pageSize + $index + 1 }}
            </template>
          </el-table-column>
          <!-- <el-table-column label="用户编号" prop="user_number" /> -->
          <el-table-column label="用户手机号" prop="phone" />
          <el-table-column
            label="电动车余额(元)"
            prop="balance"
            :formatter="rounding"
          />
          <el-table-column
            label="电动汽车余额(元)"
            prop="balance_new"
            :formatter="rounding"
          />
          <el-table-column label="运营商" prop="username" />
          <!-- <el-table-column
            label="注册时间"
            prop="last_login"
            :formatter="formatDate"
          /> -->

          <el-table-column label="操作" width="120" align="center">
            <template #default="scope">
              <el-button
                round
                color="#42a7a9"
                plain
                size="mini"
                @click="charge(scope.$index, scope.row)"
                >充值</el-button
              >
              <!-- <el-button round type="warning" plain size="mini" @click="refund(scope.$index, scope.row)">退费</el-button> -->
            </template>
          </el-table-column>
        </el-table>

        <div class="pagination">
          <div class="flex1">
            <p>
              电动车余额(元)：<span>{{
                tableData1.sum_balance ? tableData1.sum_balance : "0.00"
              }}</span>
            </p>
            <p>
              电动汽车余额(元)：<span>{{
                tableData1.sum_balance_new ? tableData1.sum_balance_new : "0.00"
              }}</span>
            </p>
            <p>
              总余额(元)：<span>{{
                tableData1.sum_money ? tableData1.sum_money : "0.00"
              }}</span>
            </p>
          </div>
          <el-pagination
            :current-page="FormData.page"
            :page-size="pageSize"
            :small="small"
            layout="total, slot, prev, pager, next, jumper"
            :total="total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          >
            <template #default>
              <span class="el-pagination__total">{{ Math.ceil(total / pageSize) }} 页 </span>
            </template>
          </el-pagination>
        </div>
        <!-- 充值弹窗 -->
        <div v-dialogdrag>
          <el-dialog
            v-model="Dialog.editLevy"
            title="充值"
            width="900px"
            :before-close="closeDialog"
            class="dialog"
          >
            <div class="formDiv">
              <el-form label-position="right" label-width="100px">
                <el-row :gutter="20">
                  <el-col :span="1"></el-col>
                  <el-col :span="9">
                    <el-form-item label="手机号:">
                      <el-input v-model="Dialog.DialogData.phone"></el-input>
                    </el-form-item>
                  </el-col>
                  <!-- <el-col :span="1"></el-col> -->
                  <el-col :span="9">
                    <el-form-item label="充值车型:">
                      <el-select
                        v-model="Dialog.DialogData.car"
                        placeholder="请选择充电类型"
                      >
                        <el-option
                          v-for="car in carList"
                          :key="car.value"
                          :value="car.value"
                          :label="car.label"
                        />
                      </el-select>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="20">
                  <el-col :span="1"></el-col>
                  <el-col :span="9">
                    <el-form-item label="充值金额(元):">
                      <el-input
                        v-model="Dialog.DialogData.charge_amount"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <!-- <el-col :span="1"></el-col> -->
                  <!-- <el-col :span="9">
                    <el-form-item label="充值金额:">
                      <el-input
                        v-model="Dialog.DialogData.charge_amount"
                      ></el-input>
                    </el-form-item>
                  </el-col> -->
                </el-row>
              </el-form>
            </div>
            <template #footer>
              <span class="dialog-footer">
                <el-button class="Btn" @click="closeDialog">取消</el-button>
                <el-button class="Btn" type="primary" @click="submitDialog"
                  >提交</el-button
                >
              </span>
            </template>
          </el-dialog>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { onMounted, reactive, toRefs } from "vue-demi";
import {
  selwallet,
  charge_money,
  prohibit_user,
  operator_data,
} from "@/request/api";
import { ElMessage, ElMessageBox } from "element-plus";
import Data2Excel from "@/component/Data2Excel.vue"
export default {
  name: "SearchMan",
  components:{
    Data2Excel
  },
  setup() {
    const data = reactive({
      //表单数据
      carList: [
        {
          value: 2,
          label: "电动车",
        },
        {
          value: 4,
          label: "电动汽车",
        },
      ],
      car: "",

      FormData: {
        page: 1,
      },
      isadmin: false,
      users: [],
      tableData: [],
      tableData1: {},

      loading: false,
      //   弹框
      Dialog: {
        editLevy: false,
        DialogData: {},
      },
      Dialog1: {
        editLevy: false,
        DialogData: {},
      },

      //   分页
      currentPage: 1,
      pageSize: 20,
      total: 0,
      checked: 0,
      small: true,
    });
    const rounding = (row, column) => {
      return parseFloat(
        row[column.property] ? row[column.property] : "0"
      ).toFixed(2);
    };
    const formatDate = (row, column) => {
      // 获取单元格数据
      let data = row[column.property];
      if (data == null) {
        return null;
      }
      let now = new Date(data);
      let year =
        now.getFullYear() < 10 ? "0" + now.getFullYear() : now.getFullYear(); //得到年份
      let month =
        now.getMonth() + 1 < 10
          ? "0" + (now.getMonth() + 1)
          : now.getMonth() + 1; //得到月份
      let date = now.getDate() < 10 ? "0" + now.getDate() : now.getDate(); //得到日期
      let hour = now.getHours() < 10 ? "0" + now.getHours() : now.getHours(); //得到小时数
      let minute =
        now.getMinutes() < 10 ? "0" + now.getMinutes() : now.getMinutes(); //得到分钟数
      let seconds =
        now.getSeconds() < 10 ? "0" + now.getSeconds() : now.getSeconds(); //得到秒数

      return (
        year +
        "-" +
        month +
        "-" +
        date +
        " " +
        hour +
        ":" +
        minute +
        ":" +
        seconds
      );
    };
    const searchBtn = () => {
      data.FormData.page = 1;
      getList();
    };

    const closeDialog = () => {
      data.Dialog.editLevy = false;
      data.Dialog1.editLevy = false;
    };
    const submitDialog = () => {
      console.log("提交");
      submitData();
    };
    const submitDialog1 = () => {
      console.log("提交");
      submitData1();
    };

    const submitData = () => {
      const dataa = {
        phone: data.Dialog.DialogData.phone,
        charge_amount: data.Dialog.DialogData.charge_amount,
        type_id: data.Dialog.DialogData.car,
      };
      console.log(dataa, "dataaaaaa");
      charge_money(dataa).then((res) => {
        console.log(res);
        if (res) {
          closeDialog();
          if (res.code == 200) {
            ElMessage({
              showClose: true,
              message: "充值成功",
              type: "success",
            });
            getList();
          } else {
            ElMessage.error(res.msg);
          }
        } else {
          ElMessage.error("提交失败");
        }
      });
    };
    const submitData1 = () => {
      const dataa = {
        // phone : data.Dialog1.DialogData.phone,
        refund: data.Dialog1.DialogData.refund_amount,
        type_id: data.Dialog1.DialogData.car,
      };
      re_money(dataa).then((res) => {
        console.log(res);
        if (res) {
          closeDialog();
          if (res.code == 200) {
            ElMessage({
              showClose: true,
              message: "退费成功",
              type: "success",
            });
            getList();
          } else {
            ElMessage.error(res.msg);
          }
        } else {
          ElMessage.error("提交失败");
        }
      });
    };

    const forbidden = (index, row) => {
      ElMessageBox.confirm("确定要禁用该用户?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          prohibit_user(row).then((res) => {
            console.log(res);
            if (res) {
              data.loading = false;
              if (res.code == 200) {
                console.log("已禁用");
                row.tableData = res.data;
              } else {
                ElMessage.error(res.msg);
              }
            } else {
              ElMessage.error("禁用失败");
            }
            getList();
          });
        })
        .catch(() => {});
    };

    const charge = (index, row) => {
      data.Dialog.editLevy = true;
      data.Dialog.DialogData = row;
    };
    const refund = (index, row) => {
      data.Dialog1.editLevy = true;
      data.Dialog1.DialogData = row;
    };
    const getList = () => {
      console.log("用户查询");
      let dataa;
      if (data.isadmin) {
        dataa = {
          page: data.FormData.page,
          phone: data.FormData.phone,
          operator_id: data.FormData.operator_id,
        };
        console.log(dataa,"admin");
      }else if(!data.isadmin){
        dataa = {
          page: data.FormData.page,
          phone: data.FormData.phone,
          operator_id: sessionStorage.getItem("userID"),
        }
        console.log(dataa,"运营商");

      }

      data.loading = true;
      selwallet(dataa).then((res) => {
        data.loading = false;
        if (res) {
          
          if (res.code == 200) {
            data.tableData = res.data;
            data.total = res.count_data;
            data.tableData1 = res;
            data.tableData1.sum_balance = Number(res.sum_balance)
            .toFixed(2)
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            data.tableData1.sum_balance_new = Number(res.sum_balance_new)
            .toFixed(2)
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            data.tableData1.sum_money = Number(res.sum_money)
            .toFixed(2)
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            data.tableData.forEach((a)=>{
              a.balance = Number(a.balance )
              .toFixed(2)
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
              a.balance_new = Number(a.balance_new )
              .toFixed(2)
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            })
          } else {
            ElMessage.error(res.msg);
          }
        } else {
          ElMessage.error("数据获取失败");
        }
      });
    };
    const getUsername = () => {
      const username1 = localStorage.getItem("username");
      const users1 = [];
      operator_data().then((res) => {
        console.log(res, "sssssss");
        data.users = res;
        res.forEach((a) => {
          users1.push(a.username);
        });
        if (users1.indexOf(username1) == -1) {
          data.isadmin = true;
        } else {
          data.isadmin = false;
        }
        getList();
      });
    };
    const handleSizeChange = (val) => {
      data.pageSize = val;
    };
    const handleCurrentChange = (val) => {
      data.FormData.page = val;
      getList();
      //
    };
    onMounted(() => {
      getUsername();
      
    });
    return {
      ...toRefs(data),
      searchBtn,
      getList,
      getUsername,
      forbidden,
      charge,
      refund,
      closeDialog,
      submitDialog,
      submitDialog1,
      submitData,
      submitData1,
      handleSizeChange,
      handleCurrentChange,
      rounding,
      formatDate,
    };
  },
};
</script>

<style>
.pagination {
  display: flex;
  justify-content: space-between;
  padding: 0 67px;
  margin-top: -10px;
}

.bottomDiv {
  height: 100%;
}
.statistics {
  display: flex;
  width: 90%;
  padding-top: 15px;
  /* background: red; */
  margin: 0 auto;
  justify-content: space-between;
  flex-wrap: wrap;
}
.statistics1 {
  display: flex;
  width: 90%;
  margin: 0 auto;
  justify-content: space-between;
  flex-wrap: wrap;
}
.statistics1 > div > a {
  text-decoration: none;
  color: black;
}
.s_title {
  font-size: 14px;
}
.s_title .el-icon {
  float: right;
  color: red;
}
.p_bottom .el-icon {
  float: right;
  color: green;
}
.s_water {
  line-height: 40px;
  font-size: 19px;
  /* font-weight: bold; */
}
.s_profress {
  line-height: 25px;
}
.statistics > div {
  width: 16%;
  margin: 10px auto;
  background: #f0f2f5;
  /* border: 1px solid red; */
  padding: 10px 20px;
  border-radius: 5px;
  /* height: 200px; */
}
.statistics > div > .el-progress {
  width: 45%;
}
.el-progress /deep/ .el-progress-bar__outer {
  --el-border-color-lighter: #fff;
}
.statistics1 > div {
  width: 13%;
  margin: 10px auto;
  /* border: 1px solid red; */
  padding: 10px;
  /* height: 200px; */
}

.BtnSearch {
  float: left;
  width: 60px;
  margin: 0 4px;
}
.flex1 {
  display: flex;
  justify-content: space-between;
  padding: 5px 10px;
  /* margin-top: -10px; */
}
.flex1 p {
  font-size: 14px;
  margin-right: 15px;
}
.flex1 span {
  color: red;
}
</style>







